import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(Link)`
 font-family: ${props => props.theme.fonts.headings};
 color: ${props => props.theme.colors.orange};
 transition: color 0.4s ${props => props.theme.eases.out};

 &:hover { color: black; }
`

const Button = ({children, to}) => (
  <StyledLink to={to}>{children}</StyledLink>
)

export default Button