import React from 'react'
import SEO from '../components/seo'
import Button from '../components/button'
import { Container, Row, Col } from 'react-grid-system'
import SocialMedia from '../components/social-media'
import { getExcerpt, stripHtml } from '../helpers'
import ComponentParser from '../components/ComponentParser'

const SinglePost = (props) => {
  return (
    <>
      <SEO data={props.pageContext.seo} />
      <Container>
        <Row>
          <Col sm={12}>
            <Button to={process.env.GATSBY_PAGE_FOR_POSTS_URI}>Retour aux actualités</Button>
            <ComponentParser content={props.pageContext.blocks} />
            <SocialMedia pagePath={props.location.href} pageTitle={props.pageContext.title} pageExcerpt={getExcerpt(stripHtml(props.pageContext.content), 100)} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SinglePost