import React, { useRef, useState } from 'react'
import Share from '../../../static/svg/share.svg'
import Copy from '../../../static/svg/copy.svg'
import Twitter from '../../../static/svg/twitter.svg'
import Facebook from '../../../static/svg/facebook.svg'
import Linkedin from '../../../static/svg/linkedin.svg'
import styled, { css } from 'styled-components'

const setLoopCss = () => {
  let styles = ''

  for (let i = 1; i < 5; i += 1) {
     styles += `
      &:nth-child(${i}) {
        transition-delay: ${0.05 * i}s;
      }
     `
  }

  return css`${styles}`;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;

  .social-media {
    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      margin-bottom: 10px;
    }

    &__text {
      font-family: ${props => props.theme.fonts.headings};
      margin-right: 10px;
    }

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition:
        opacity 0.75s ${props => props.theme.eases.out},
        transform 0.75s ${props => props.theme.eases.out};

      &.big {
        width: 50px;
        height: 50px;
        border-radius: 50px;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        fill: black;
        transition: fill 0.4s ${props => props.theme.eases.out};
      }

      &:hover svg { fill: black; }
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .social-media__item {
        margin: 0 10px;
        opacity: ${props => props.toggle ? 1 : 0};
        transform: ${props => props.toggle ? `translateY(0)` : `translateY(10px)`};
        pointer-events: ${props => props.toggle ? `all` : `none`};

        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }

        ${setLoopCss()};

        svg {
          transition:
            transform 0.4s ${props => props.theme.eases.out},
            fill 0.4s ${props => props.theme.eases.out};
        }

        &:hover {
          svg { transform: scale(1.1); }
          &.twitter svg { fill: #1da1f2; }
          &.facebook svg { fill: #3b5998; }
          &.linkedin svg { fill: #2867b2; }
        }

        &.copy {
          textarea {
            width: 0;
            height: 0;
            padding: 0;
            opacity: 0;
          }

          .msg {
            font-family: ${props => props.theme.fonts.headings};
            color: black;
            font-size: 13px;
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            opacity: ${props => props.visible ? 1 : 0};
            transition: opacity 0.75s ${props => props.theme.eases.out};
          }
        }
      }
    }
  }
`

const SocialMedia = ({ pagePath, pageTitle, pageExcerpt }) => {
  const [toggle, setToggle] = useState(false)
  const [msgVisible, setMsgVisible] = useState(false)
  const socialToggler = useRef(null)
  const copyLink = useRef(null)
  const msg = useRef(null)

  let copied = false

  const copyToClipboard = () => {
    if (copied) return
  
    copied = true
    const textArea = document.createElement('textarea')
  
    textArea.value = pagePath
    copyLink.current.appendChild(textArea)
    textArea.select()
  
    try {
      document.execCommand('copy')

      msg.current.innerHTML = 'Copié'
      setMsgVisible(true)

      window.setTimeout(() => setMsgVisible(false), 2500)

      window.setTimeout(() => {
        msg.current.innerHTML = ''
        copied = false
      }, 3500)
    } catch (err) {
      msg.current.innerHTML = 'Erreur'
      setMsgVisible(true)
  
      window.setTimeout(() => setMsgVisible(false), 2500)
  
      window.setTimeout(() => {
        msg.current.innerHTML = ''
        copied = false
      }, 3500)
    }
  
    copyLink.current.removeChild(textArea)
  }

  return (
    <Wrapper toggle={toggle} visible={msgVisible} className="social-media">
      <div className="social-media__button" ref={socialToggler} onClick={() => setToggle(!toggle)} onKeyDown={() => setToggle(!toggle)} role="button" tabIndex={0}>
        <div className="social-media__item big"><Share/></div>
        <div className="social-media__text">Partager l'article</div>
      </div>
      <div className="social-media__list">
        <a className="social-media__item linkedin" target="_blank" rel="noopener noreferrer" aria-label="Linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=${pagePath}&title=${pageTitle}&summary=${pageExcerpt}`}><Linkedin/></a>
        <a className="social-media__item facebook" target="_blank" rel="noopener noreferrer" aria-label="Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${pagePath}`}><Facebook/></a>
        <a className="social-media__item twitter" target="_blank" rel="noopener noreferrer" aria-label="Twitter" href={`http://twitter.com/share?text=${pageTitle}&url=${pagePath}`}><Twitter/></a>
        <div className="social-media__item copy" ref={copyLink} onClick={copyToClipboard} onKeyDown={copyToClipboard} role="button" tabIndex={0}>
          <Copy/>
          <span className="msg" ref={msg}></span>
        </div>
      </div>
    </Wrapper>
  )
}

export default SocialMedia